import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/SEO';

const Impressum = () => (
    <Layout>
        <SEO title="Impressum" />
        <section className="page-title">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Impressum</h1>
                    </div>
                </div>
            </div>
        </section>
        <section className="simple-page">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div id="content" className="it-blog skin-light background-content wrap-wide has-background background-type-color">
                            <div className="container">
                                <div className="row">
                                    <div className="entry-content col-md-12 ">
                                        <p>
                                            <b>
                                                Verantwortlicher Diensteanbieter:
                                                <br />
                                            </b>
                                            Bauer &amp; Dr. Pauly Rechtsanwälte
                                            <br />
                                            Rechtsanwälte:
                                            <br />
                                            Holger Bauer
                                            <br />
                                            Dr. Bettina Pauly
                                            <br />
                                            Steffen Eberhard
                                        </p>
                                        <p>
                                            Telefon: 0711 46 98 777
                                            <br />
                                            Telefax: 0711 46 98 775
                                        </p>
                                        <p>E-Mail: kanzlei@bauerpauly.de</p>
                                        <p>
                                            Die Anwälte der Kanzlei führen die gesetzlichen Berufsbezeichnungen „Rechtsanwalt“. Diese Berufsbezeichnungen wurden
                                            in der Bundesrepublik Deutschland, Bundesland Baden-Württemberg verliehen.
                                        </p>
                                        <p>
                                            <b>
                                                Zuständige Aufsichtsbehörde ist:
                                                <br />
                                            </b>
                                            Rechtsanwaltskammer Stuttgart
                                            <br />
                                            Königstraße 14, 70173 Stuttgart
                                            <br />
                                            Telefon:&nbsp;0711 &nbsp;22 21 55-0
                                            <br />
                                            Telefax:&nbsp;0711 22 21 55-11
                                            <br />
                                            E-Mail:&nbsp;
                                            <a className="mail" title="ea@rak-stuttgart.de" href="mailto:info@rak-stuttgart.de">
                                                info@rak-stuttgart.de
                                            </a>
                                            <br />
                                            <a href="http://www.rak-stuttgart.de">www.rak-stuttgart.de</a>
                                        </p>
                                        <p>
                                            <strong>Berufliche Haftpflichtversicherung für alle Anwälte der Kanzlei:</strong>
                                            <br />
                                            AFB GmbH,&nbsp;Kaistr. 13, 40221 Düsseldorf
                                            <br />
                                            <a href="mailto:info@afb24.de">info@afb24.de</a>, Tel.: 0211 4936565
                                            <br />
                                            Räumlicher Geltungsbereich:&nbsp; Deutschlandweit
                                        </p>
                                        <p>
                                            <strong>Steuernummer &amp; Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</strong>
                                            <br />
                                            Holger Bauer,&nbsp;USt-ID Nr.: DE240266295
                                            <br />
                                            Dr. Bettina Pauly,&nbsp;USt-ID Nr.: DE296951285
                                            <br />
                                            Steffen Eberhard, USt-ID Nr.: DE244755601
                                        </p>
                                        <p>&nbsp;</p>
                                        <p>
                                            <b>Berufsrechtliche Regelungen:</b>
                                        </p>
                                        <ul>
                                            <li>Bundesrechtsanwaltsordnung (BRAO)</li>
                                            <li>Berufsordnung der Rechtsanwälte (BORA)</li>
                                            <li>Fachanwaltsordnung (FAO)</li>
                                            <li>Rechtsanwaltsvergütungsgesetz (RVG)</li>
                                        </ul>
                                        <p>
                                            Diese Vorschriften können sie <a href="http://www.brak.de/fuer-anwaelte/berufsrecht/">hier</a> einsehen.
                                        </p>
                                        <p>
                                            <b>Außergerichtliche Streitschlichtung&nbsp;</b>
                                        </p>
                                        <p>
                                            Bei Streitigkeiten zwischen Rechtsanwälten und ihren Mandanten besteht auf Antrag die Möglichkeit der
                                            außergerichtlichen Streitschlichtung bei der regionalen Rechtsanwaltskammer Stuttgart oder bei der
                                            Schlichtungsstelle der Rechtsanwaltschaft bei der Bundesrechtsanwaltskammer. Kontakt zu dieser erhalten Sie über die
                                            Homepage der Bundesrechtsanwaltskammer (http://www.brak.de), E-Mail:{' '}
                                            <a href="mailto:schlichtungsstelle@brak.de">schlichtungsstelle@brak.de.</a>
                                        </p>
                                        <p>
                                            Online-Streitbeilegung (Art. 14 Abs. 1 ODR-Verordnung): Die Europäische Kommission stellt unter{' '}
                                            <a href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage">
                                                ec.europa.eu/consumers/odr
                                            </a>
                                            / eine Plattform zur Online-Streitbeilegung bereit.
                                        </p>
                                        <p>
                                            Herr Rechtanwalt Holger Bauer, Frau Rechtsanwältin&nbsp;Dr. Bettina Pauly und Herr Rechtsanwalt Steffen Eberhard
                                            sind nicht verpflichtet und nicht bereit, an Streit-Beilegungsverfahren vor einer Verbraucherschlichtungsstelle
                                            teilzunehmen.
                                        </p>
                                        <p>
                                            <b>Nutzungsbestimmungen:</b>
                                        </p>
                                        <p>
                                            Die Nutzung unserer Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie E-Mail-Adressen durch Dritte zur
                                            Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche Schritte gegen die
                                            Versender derartiger Informationen behalten wir uns ausdrücklich vor. Inhalt und Struktur unserer Internetseiten
                                            sind urheberrechtlich geschützt. Die Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von
                                            Texten, Textteilen oder Bildmaterial, ist ohne unsere vorherige schriftliche Zustimmung nicht gestattet.
                                            Zuwiderhandlungen werden straf- und zivilrechtlich verfolgt.
                                            <br />
                                            Alle auf dieser Internetseite genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe
                                            ihrer Daten (§ 28 Bundesdatenschutzgesetz BDSG).
                                        </p>
                                        <p>
                                            <b>Urheberrecht</b>
                                            <br />
                                            Alle auf dieser Internetseite veröffentlichten Beiträge und Abbildungen sind urheberrechtlich geschützt. Jede vom
                                            Urheberrechtsgesetz nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters. Dies
                                            gilt insbesondere für die Vervielfältigung, Bearbeitung, Verarbeitung, Einspeicherung, Übersetzung bzw. Wiedergabe
                                            von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Fotokopien und Downloads von unseren
                                            Webseiten dürfen nur für den persönlichen, privaten und nichtkommerziellen Gebrauch hergestellt werden.
                                        </p>
                                        <p>
                                            <b>Kommunikation über Email</b>
                                            <br />
                                            Kommunikation über E-Mail kann Sicherheitslücken aufweisen. Sollten wir eine E-Mail von Ihnen erhalten, gehen wir
                                            davon aus, dass wir zu einer Beantwortung per E-Mail berechtigt sind. Sofern Sie dies nicht wünschen, müssen Sie
                                            ausdrücklich auf eine andere Art der gewünschten Kommunikation hinweisen. Vorsorglich weisen wir außerdem darauf
                                            hin, dass die Bearbeitung eingehender E-Mails gegenüber dem Posteingang und Telefaxmitteilungen grundsätzlich nicht
                                            vorgezogen wird.
                                        </p>
                                        <p>
                                            <b>Unverbindlichkeit</b>
                                            <br />
                                            Die Inhalte dieser Internetseite werden von uns mit größtmöglicher Sorgfalt recherchiert. Wir übernehmen gleichwohl
                                            keine Haftung für Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Informationen und Beiträge.
                                            Letztere stellen die Meinung des jeweiligen Autors dar. Mündliche und schriftliche Auskünfte, die außerhalb eines
                                            Mandatsverhältnisses erteilt werden, sind unverbindlich.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default Impressum;
